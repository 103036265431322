.react-calendar__navigation {
  @apply flex items-center justify-center space-x-2;
}

.react-calendar__navigation__label {
  @apply text-xl font-bold;
}

.react-calendar__navigation__arrow {
  @apply flex flex-1 justify-center;
}

.react-calendar__month-view__weekdays__weekday {
  @apply text-center font-bold;
}

.react-calendar__viewContainer {
  @apply mt-4;
}

.react-calendar__month-view__days__day {
  @apply relative h-[13vw] w-full md:my-2 md:h-[5vw];
}

.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-grey-4;
}

.react-calendar__tile--active {
  @apply rounded-[100%] !bg-brand-1 text-white;
}

.react-calendar__tile--now {
  @apply font-bold text-brandblue-1;
}

.react-calendar__tile--active.react-calendar__tile--now {
  @apply text-brandblue-1;
}
