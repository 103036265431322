#top {
  height: 49px;
  border-bottom: 1px solid #bbb;
  padding: 16px;
  font-size: 10px;
}

#lnb {
  position: absolute;
  width: 200px;
  top: 0px;
  bottom: 0;
  border-right: 1px solid #d5d5d5;
  padding: 12px 10px;
  background: #fafafa;
}

#right {
  position: absolute;
  left: 200px;
  top: 0px;
  right: 0;
  bottom: 0;
}

#lnb label {
  margin-bottom: 0;
  cursor: pointer;
}

.lnb-calendars > div {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: normal;
}

.lnb-calendars-d1 {
  padding-left: 8px;
}

.lnb-calendars-d1 label {
  font-weight: normal;
}

.lnb-calendars-item {
  min-height: 14px;
  line-height: 14px;
  padding: 8px 0;
}

.lnb-footer {
  color: #999;
  font-size: 11px;
  position: absolute;
  bottom: 12px;
  padding-left: 16px;
}

#menu {
  padding: 16px;
}

.tui-full-calendar-month {
  min-height: 100%;
}

.lnb-calendars-item {
  padding: 3px 0;
}
