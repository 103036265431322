@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css');
/* @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-gov-dynamic-subset.min.css'); */

body {
  font-family:
    'Pretendard',
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    'Helvetica Neue',
    'Segoe UI',
    'Apple SD Gothic Neo',
    'Noto Sans KR',
    'Malgun Gothic',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  body {
    @apply text-gray-800;
  }
  button,
  input,
  textarea,
  select {
    @apply focus:outline-none focus:ring-0;
  }
}

@layer components {
  .badge {
    @apply inline-flex cursor-pointer items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800;
  }
  .bottom-nav {
    @apply fixed bottom-0 flex w-full border-t bg-white py-1;
  }
  .bottom-nav-item {
    @apply flex h-12 flex-1 flex-col items-center justify-center text-xs font-medium text-gray-300 outline-none focus:outline-none focus:ring-0;
  }
  .checkbox {
    @apply h-4 w-4 rounded border-gray-300 text-brand-1 focus:ring-brand-1;
  }
  .chip {
    @apply whitespace-nowrap rounded-full bg-gray-100 px-4 py-2 text-sm text-gray-800;
  }
  .chip-selected {
    @apply bg-brand-1 text-white;
  }
  .file-upload {
    @apply rounded border-2 border-dashed border-grey-5 bg-white;
  }
  .horizontal-scroll-view-inner {
    @apply mx-5 flex items-center space-x-2;
  }
  .horizontal-scroll-view-outer {
    @apply overflow-x-auto;
    ms-overflow-style: 'none';
    scrollbarwidth: 'none';
  }
  .image-upload {
    @apply flex aspect-5/3 cursor-pointer flex-col items-center justify-center space-y-1 rounded border-2 border-dashed border-grey-5 bg-white;
  }
  .list {
    @apply flex flex-col;
  }
  .pagination {
    @apply flex items-center justify-center;
  }
  .pagination-link {
    @apply grid h-10 w-10 place-items-center rounded-sm text-14 text-gray-500 hover:bg-gray-50;
  }
  .radio {
    @apply h-4 w-4 border-gray-300 text-brand-1 disabled:text-gray-600;
  }
  .section {
    @apply flex flex-col space-y-4 px-2 py-4 xl:px-5;
  }
  .select-xs {
    @apply h-5 rounded-sm border border-gray-200 text-12 font-light placeholder-gray-400 focus:border-brand-1 disabled:bg-gray-100 disabled:text-gray-400;
  }
  .select-sm {
    @apply h-7 rounded border border-gray-200 text-13 placeholder-gray-400 focus:border-brand-1 disabled:bg-gray-100 disabled:text-gray-400;
  }
  .select {
    @apply h-9 rounded-md border border-gray-200 text-14 placeholder-gray-400 focus:border-brand-1 disabled:bg-gray-100 disabled:text-gray-400;
  }
  .select-lg {
    @apply h-11 rounded-lg border border-gray-200 text-15 font-medium placeholder-gray-400 focus:border-brand-1 disabled:bg-gray-100 disabled:text-gray-400;
  }
  .select-xl {
    @apply h-13 rounded-lg border border-gray-200 text-16 font-bold placeholder-gray-400 focus:border-brand-1 disabled:bg-gray-100 disabled:text-gray-400;
  }
  .tabs {
    @apply flex h-10 overflow-y-hidden border-b bg-white;
  }
  .tabs-item {
    @apply flex flex-1 items-center justify-center border-b-2 border-transparent text-sm text-gray-500;
  }
  .tabs-item-selected {
    @apply border-brand-1 font-bold;
  }
  .ib-tabs {
    @apply w-fit border-collapse bg-white;
  }
  .ib-tabs-item {
    @apply w-22 cursor-pointer border border-gray-300 py-2 text-center;
  }
  .ib-tabs-item-selected {
    @apply bg-gray-300;
  }
  .textarea {
    @apply block h-48 w-full rounded-md border border-gray-200 px-4 py-2 placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400;
  }
  .text-input {
    @apply block h-12 w-full rounded-md border border-gray-200 px-4 placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400;
  }
  .user-text-input {
    @apply block w-full rounded-lg border border-gray-200 px-4 py-4 placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400;
  }
  .student-text-input {
    @apply block w-full border border-gray-200 text-sm placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400;
  }
  .time {
    @apply text-sm font-light text-grey-3;
  }
  .timetable th,
  .timetable td {
    @apply h-14 w-16 border p-0 text-center;
  }
}

@layer utilities {
  .snap {
    scroll-snap-type: var(—scroll-snap-direction) var(—scroll-snap-constraint);
  }
  .snap-x {
    —scroll-snap-direction: x;
    scroll-snap-direction: x;
    scroll-snap-type: x;
  }
  .snap-mandatory {
    —scroll-snap-constraint: mandatory;
  }
  .snap-start {
    scroll-snap-align: start;
  }
}

@layer utilities {
  .scrollable {
    overflow-x: auto;
    padding: 1px;
  }
  .scrollable::-webkit-scrollbar {
    width: 70px;
    height: 3px;
  }
  .scrollable::-webkit-scrollbar-thumb {
    background-color: rgba(216, 219, 223, 1);
    border-radius: 4px;
  }
  .scrollable::-webkit-scrollbar-track {
    background: transparent;
  }
}

@layer utilities {
  .scrollable-wide {
    overflow-x: auto;
    padding: 3px;
  }
  .scrollable-wide::-webkit-scrollbar {
    width: 70px;
    height: 4px;
  }
  .scrollable-wide::-webkit-scrollbar-thumb {
    background-color: rgba(216, 219, 223, 1);
    border-radius: 4px;
  }
  .scrollable-wide::-webkit-scrollbar-track {
    background: transparent;
  }
}

.scroll-box {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-box::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.card_shadow {
  border: 1px solid #e5e8eb;
  box-shadow:
    0px 4px 30px rgba(0, 0, 0, 0.04),
    0px 4px 30px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
}

.feedback_space a {
  color: #2f80ed;
}
.feedback_space a:hover {
  text-decoration: underline;
}

.svda-title-editor-placeholder {
  color: white;
}

.svda-title-editor-placeholder[aria-label='title']::after {
  content: '제목을 입력해주세요.';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #404040;
}

.svda-title-editor-placeholder[aria-label='description']::after {
  content: '설명을 입력해주세요.';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #404040;
}

.not-draggable {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

select option[disabled] {
  display: none;
}
